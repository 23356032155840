exports.components = {
  "component---src-pages-architecture-en-js": () => import("./../../../src/pages/architecture.en.js" /* webpackChunkName: "component---src-pages-architecture-en-js" */),
  "component---src-pages-architecture-hr-js": () => import("./../../../src/pages/architecture.hr.js" /* webpackChunkName: "component---src-pages-architecture-hr-js" */),
  "component---src-pages-author-en-js": () => import("./../../../src/pages/author.en.js" /* webpackChunkName: "component---src-pages-author-en-js" */),
  "component---src-pages-author-hr-js": () => import("./../../../src/pages/author.hr.js" /* webpackChunkName: "component---src-pages-author-hr-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-hr-js": () => import("./../../../src/pages/contact.hr.js" /* webpackChunkName: "component---src-pages-contact-hr-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-hr-js": () => import("./../../../src/pages/index.hr.js" /* webpackChunkName: "component---src-pages-index-hr-js" */),
  "component---src-pages-interiors-back-room-en-js": () => import("./../../../src/pages/interiors/back-room.en.js" /* webpackChunkName: "component---src-pages-interiors-back-room-en-js" */),
  "component---src-pages-interiors-back-room-hr-js": () => import("./../../../src/pages/interiors/back-room.hr.js" /* webpackChunkName: "component---src-pages-interiors-back-room-hr-js" */),
  "component---src-pages-interiors-game-of-lights-en-js": () => import("./../../../src/pages/interiors/game-of-lights.en.js" /* webpackChunkName: "component---src-pages-interiors-game-of-lights-en-js" */),
  "component---src-pages-interiors-game-of-lights-hr-js": () => import("./../../../src/pages/interiors/game-of-lights.hr.js" /* webpackChunkName: "component---src-pages-interiors-game-of-lights-hr-js" */),
  "component---src-pages-interiors-index-en-js": () => import("./../../../src/pages/interiors/index.en.js" /* webpackChunkName: "component---src-pages-interiors-index-en-js" */),
  "component---src-pages-interiors-index-hr-js": () => import("./../../../src/pages/interiors/index.hr.js" /* webpackChunkName: "component---src-pages-interiors-index-hr-js" */),
  "component---src-pages-interiors-private-en-js": () => import("./../../../src/pages/interiors/private.en.js" /* webpackChunkName: "component---src-pages-interiors-private-en-js" */),
  "component---src-pages-interiors-private-hr-js": () => import("./../../../src/pages/interiors/private.hr.js" /* webpackChunkName: "component---src-pages-interiors-private-hr-js" */),
  "component---src-pages-interiors-public-and-business-en-js": () => import("./../../../src/pages/interiors/public-and-business.en.js" /* webpackChunkName: "component---src-pages-interiors-public-and-business-en-js" */),
  "component---src-pages-interiors-public-and-business-hr-js": () => import("./../../../src/pages/interiors/public-and-business.hr.js" /* webpackChunkName: "component---src-pages-interiors-public-and-business-hr-js" */),
  "component---src-pages-press-55-zg-salon-en-js": () => import("./../../../src/pages/press/55-zg-salon.en.js" /* webpackChunkName: "component---src-pages-press-55-zg-salon-en-js" */),
  "component---src-pages-press-55-zg-salon-hr-js": () => import("./../../../src/pages/press/55-zg-salon.hr.js" /* webpackChunkName: "component---src-pages-press-55-zg-salon-hr-js" */),
  "component---src-pages-press-index-en-js": () => import("./../../../src/pages/press/index.en.js" /* webpackChunkName: "component---src-pages-press-index-en-js" */),
  "component---src-pages-press-index-hr-js": () => import("./../../../src/pages/press/index.hr.js" /* webpackChunkName: "component---src-pages-press-index-hr-js" */),
  "component---src-pages-product-design-en-js": () => import("./../../../src/pages/product-design.en.js" /* webpackChunkName: "component---src-pages-product-design-en-js" */),
  "component---src-pages-product-design-hr-js": () => import("./../../../src/pages/product-design.hr.js" /* webpackChunkName: "component---src-pages-product-design-hr-js" */),
  "component---src-pages-projects-space-as-a-pleasure-back-room-en-js": () => import("./../../../src/pages/projects/space-as-a-pleasure/back-room.en.js" /* webpackChunkName: "component---src-pages-projects-space-as-a-pleasure-back-room-en-js" */),
  "component---src-pages-projects-space-as-a-pleasure-back-room-hr-js": () => import("./../../../src/pages/projects/space-as-a-pleasure/back-room.hr.js" /* webpackChunkName: "component---src-pages-projects-space-as-a-pleasure-back-room-hr-js" */),
  "component---src-pages-projects-space-as-a-pleasure-dressed-to-impress-en-js": () => import("./../../../src/pages/projects/space-as-a-pleasure/dressed-to-impress.en.js" /* webpackChunkName: "component---src-pages-projects-space-as-a-pleasure-dressed-to-impress-en-js" */),
  "component---src-pages-projects-space-as-a-pleasure-dressed-to-impress-hr-js": () => import("./../../../src/pages/projects/space-as-a-pleasure/dressed-to-impress.hr.js" /* webpackChunkName: "component---src-pages-projects-space-as-a-pleasure-dressed-to-impress-hr-js" */),
  "component---src-pages-projects-space-as-a-pleasure-flirt-with-art-en-js": () => import("./../../../src/pages/projects/space-as-a-pleasure/flirt-with-art.en.js" /* webpackChunkName: "component---src-pages-projects-space-as-a-pleasure-flirt-with-art-en-js" */),
  "component---src-pages-projects-space-as-a-pleasure-flirt-with-art-hr-js": () => import("./../../../src/pages/projects/space-as-a-pleasure/flirt-with-art.hr.js" /* webpackChunkName: "component---src-pages-projects-space-as-a-pleasure-flirt-with-art-hr-js" */),
  "component---src-pages-projects-space-as-a-pleasure-from-purity-to-en-js": () => import("./../../../src/pages/projects/space-as-a-pleasure/from-purity-to.en.js" /* webpackChunkName: "component---src-pages-projects-space-as-a-pleasure-from-purity-to-en-js" */),
  "component---src-pages-projects-space-as-a-pleasure-from-purity-to-hr-js": () => import("./../../../src/pages/projects/space-as-a-pleasure/from-purity-to.hr.js" /* webpackChunkName: "component---src-pages-projects-space-as-a-pleasure-from-purity-to-hr-js" */),
  "component---src-pages-projects-space-as-a-pleasure-good-vibrations-en-js": () => import("./../../../src/pages/projects/space-as-a-pleasure/good-vibrations.en.js" /* webpackChunkName: "component---src-pages-projects-space-as-a-pleasure-good-vibrations-en-js" */),
  "component---src-pages-projects-space-as-a-pleasure-good-vibrations-hr-js": () => import("./../../../src/pages/projects/space-as-a-pleasure/good-vibrations.hr.js" /* webpackChunkName: "component---src-pages-projects-space-as-a-pleasure-good-vibrations-hr-js" */),
  "component---src-pages-projects-space-as-a-pleasure-im-the-wild-one-en-js": () => import("./../../../src/pages/projects/space-as-a-pleasure/im-the-wild-one.en.js" /* webpackChunkName: "component---src-pages-projects-space-as-a-pleasure-im-the-wild-one-en-js" */),
  "component---src-pages-projects-space-as-a-pleasure-im-the-wild-one-hr-js": () => import("./../../../src/pages/projects/space-as-a-pleasure/im-the-wild-one.hr.js" /* webpackChunkName: "component---src-pages-projects-space-as-a-pleasure-im-the-wild-one-hr-js" */),
  "component---src-pages-projects-space-as-a-pleasure-index-en-js": () => import("./../../../src/pages/projects/space-as-a-pleasure/index.en.js" /* webpackChunkName: "component---src-pages-projects-space-as-a-pleasure-index-en-js" */),
  "component---src-pages-projects-space-as-a-pleasure-index-hr-js": () => import("./../../../src/pages/projects/space-as-a-pleasure/index.hr.js" /* webpackChunkName: "component---src-pages-projects-space-as-a-pleasure-index-hr-js" */),
  "component---src-pages-projects-space-as-a-pleasure-pleasure-empire-en-js": () => import("./../../../src/pages/projects/space-as-a-pleasure/pleasure-empire.en.js" /* webpackChunkName: "component---src-pages-projects-space-as-a-pleasure-pleasure-empire-en-js" */),
  "component---src-pages-projects-space-as-a-pleasure-pleasure-empire-hr-js": () => import("./../../../src/pages/projects/space-as-a-pleasure/pleasure-empire.hr.js" /* webpackChunkName: "component---src-pages-projects-space-as-a-pleasure-pleasure-empire-hr-js" */),
  "component---src-pages-projects-space-as-a-pleasure-x-rated-en-js": () => import("./../../../src/pages/projects/space-as-a-pleasure/x-rated.en.js" /* webpackChunkName: "component---src-pages-projects-space-as-a-pleasure-x-rated-en-js" */),
  "component---src-pages-projects-space-as-a-pleasure-x-rated-hr-js": () => import("./../../../src/pages/projects/space-as-a-pleasure/x-rated.hr.js" /* webpackChunkName: "component---src-pages-projects-space-as-a-pleasure-x-rated-hr-js" */),
  "component---src-pages-projects-streets-of-zagreb-en-js": () => import("./../../../src/pages/projects/streets-of-zagreb.en.js" /* webpackChunkName: "component---src-pages-projects-streets-of-zagreb-en-js" */),
  "component---src-pages-projects-streets-of-zagreb-hr-js": () => import("./../../../src/pages/projects/streets-of-zagreb.hr.js" /* webpackChunkName: "component---src-pages-projects-streets-of-zagreb-hr-js" */),
  "component---src-pages-projects-zagorje-in-heart-en-js": () => import("./../../../src/pages/projects/zagorje-in-heart.en.js" /* webpackChunkName: "component---src-pages-projects-zagorje-in-heart-en-js" */),
  "component---src-pages-projects-zagorje-in-heart-hr-js": () => import("./../../../src/pages/projects/zagorje-in-heart.hr.js" /* webpackChunkName: "component---src-pages-projects-zagorje-in-heart-hr-js" */)
}

